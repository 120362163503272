import React from "react"
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  ResponsiveContext,
  Text,
  Button,
} from "grommet"
import SquareImage from "./common/SquareImage"

import { getImage } from "gatsby-plugin-image"

import StackedAvatars from "./common/StackedAvatars"
import Anchor from "./common/Anchor"

const ArticleCard = ({
  image: imageData,
  slug,
  title,
  authors,
  description,
  small = false,
  path_prefix,
}) => {
  const image = getImage(imageData.localFile)
  const size = React.useContext(ResponsiveContext)

  return (
    <Card
      as={Anchor}
      margin="medium"
      height={
        size === "small" ? undefined : { min: small ? "xsmall" : "small" }
      }
      width="xlarge"
      background="light-1"
      direction={size === "small" ? "column" : "row"}
      href={`/${path_prefix}/${slug}/`}
    >
      <Box
        width={size === "small" ? undefined : "25%"}
        height={size === "small" ? "small" : undefined}
      >
        <SquareImage fill={true} image={image} alt="" />
      </Box>
      <Box
        direction="column"
        pad={
          size === "small"
            ? { horizontal: "small", vertical: "medium" }
            : { horizontal: "medium", vertical: "medium" }
        }
        gap={size === "small" ? (small ? "medium" : "large") : "small"}
        width="75%"
        fill={"horizontal"}
        align={size === "small" ? "center" : undefined}
      >
        <CardHeader>
          <Heading
            level={small ? 3 : 2}
            margin="none"
            textAlign={size === "small" ? "center" : undefined}
          >
            {title}
          </Heading>
        </CardHeader>
        <CardBody>
          <Text
            textAlign={size === "small" ? "center" : undefined}
            size={small ? "small" : undefined}
          >
            {description}
          </Text>
        </CardBody>
        {authors && (
          <CardFooter pad={{ horizontal: "small" }} background="light-1">
            <StackedAvatars authors={authors} size="small" />
          </CardFooter>
        )}
      </Box>
    </Card>
  )
}

export default ArticleCard
