import React from "react"
import { Avatar as GrommetAvatar, Box } from "grommet"
import { GatsbyImage } from "gatsby-plugin-image"

const Avatar = ({ image, alt, imgStyle, size, ...rest }) => {
  const style =
    image.width > image.height ? { height: "100%" } : { width: "100%" }
  return (
    <GrommetAvatar id="avatar" style={{ zIndex: 999 }} size={size}>
      <GatsbyImage
        image={image}
        alt=""
        style={style}
        // imgStyle={{ objectFit: "contain", height: "100%", width: "100%" }}
      />
    </GrommetAvatar>
  )
}

Avatar.propTypes = {}

Avatar.defaultProps = {
  size: "large",
}

export default Avatar
