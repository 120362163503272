import React from "react"

import { Box } from "grommet"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const SquareBox = styled(Box)`
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`

const SquareImage = ({ image, alt, imgStyle, ...rest }) => {
  return (
    <SquareBox alignSelf="center" {...rest}>
      <GatsbyImage
        image={image}
        alt=""
        imgStyle={imgStyle}
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />
    </SquareBox>
  )
}

SquareImage.propTypes = {}

export default SquareImage
