import React from "react"

import { graphql } from "gatsby"
import { Heading, Text, Box } from "grommet"
import ArticleCard from "../../../components/ArticleCard"
import Anchor from "../../../components/common/Anchor"
import PageLayout from "../../../components/Layouts/PageLayout"

export const query = graphql`
  query($slug: String!, $locale: String!) {
    strapiCategory(slug: { eq: $slug }, locale: { eq: $locale }) {
      id
      articles {
        content
        description
        updated_at
        published_at
        title
        slug
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      index {
        path_prefix
      }
      name
      slug
      updated_at
      published_at
      seo {
        id
        noIndex
        metaDescription
        metaTitle
        shareImage {
          width
          height
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    strapiArticleIndex(locale: { eq: $locale }) {
      path_prefix
    }
  }
`

const CategoryPage = ({
  data: {
    strapiCategory: { slug, name, articles, index },
    strapiArticleIndex: { path_prefix: article_path_prefix },
  },
}) => {
  return (
    <PageLayout>
      <PageLayout.Heading
        heading={name}
        sub_heading={
          <Anchor href={`/${index.path_prefix}/`}>
            <Text>All Categories</Text>
          </Anchor>
        }
      />
      <PageLayout.Content align="center">
        {articles.map(article => (
          <ArticleCard
            {...article}
            path_prefix={article_path_prefix}
            key={article.slug}
          />
        ))}
      </PageLayout.Content>
    </PageLayout>
  )
}

CategoryPage.propTypes = {}

export default CategoryPage
