import React from "react"

import Avatar from "./Avatar"
import { Stack, Box } from "grommet"
import { getImage } from "gatsby-plugin-image"

const AvatarImage = ({ image, alt, ...rest }) => {
  const _image = getImage(image)
  return <Avatar image={_image} alt={alt} {...rest} />
}

const StackedAvatars = ({ authors, size }) => {
  return (
    <Box anchor="right" direction="row" gap="xsmall">
      {authors.map((author, i) => (
        <AvatarImage
          key={author.name}
          image={author.picture.localFile}
          size="40px"
          margin={`${i * 50}px`}
        />
      ))}
    </Box>
  )
}

StackedAvatars.propTypes = {}

export default StackedAvatars
